.title {
  display: block;
  margin: 8px 0;
  font-size: 24px;
  position: relative;
}

.header {
  font-family: var(--font-vcr);
}

:root {
  --primary: #FFF;
  --secondary: #33323a;
  --tertiary: #1b1c1e;
  --highlight: #facacf;
  --trueblack: #000000;
  --icon-color: invert(100%);
  --color-negative: #e8850d;
  --black-and-white-negative: var(--primary);
  --slider-default-width: calc(100vw - 16px);
  --slider-default-height: calc(100vh - 160px);
  --text-shadow: 0px 0px 1px currentColor;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  background-color: var(--trueblack);
  overscroll-behavior: none;
}

body {
  line-height: 1;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: var(--primary);
  font-size: 16px;
  margin: 8px;
  background: var(--trueblack);
  font-family: var(--font-negative);
}

body a {
  opacity: 1;
  color: var(--highlight);
}

body strong {
  font-weight: 700;
}

body em {
  font-style: italic;
}

body h1, body h2, body h3 {
  font-family: var(--font-negative);
  font-weight: 700;
  font-size: 16px;
}

head {
  &:has(link[rel='canonical'][href='https://killerrabb.it']) + body li#home a {
    text-shadow: var(--text-shadow);
  }
  &:has(link[rel='canonical'][href*='/posts']) + body li#posts a {
    text-shadow: var(--text-shadow);
  }
  &:has(link[rel='canonical'][href*='/videos']) + body li#videos a {
    text-shadow: var(--text-shadow);
  }
}

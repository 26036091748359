@font-face {
font-family: '__vcrFont_dd05e4';
src: url(/_next/static/media/08049f812012593f.p.woff2) format('woff2');
font-display: swap;
}.__className_dd05e4 {font-family: '__vcrFont_dd05e4'
}.__variable_dd05e4 {--font-vcr: '__vcrFont_dd05e4'
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/e0464a10d3306e30.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/708587b978eb6604.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/062f2b851e15206c.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/6dd418481eb9a084.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/6e8e9d2faa3a9676.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__robotoFont_04e32a';
src: url(/_next/static/media/6235993beb016c4a.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}.__className_04e32a {font-family: '__robotoFont_04e32a'
}.__variable_04e32a {--font-negative: '__robotoFont_04e32a'
}

.Footer_footer__y_dWn {
  margin: 16px 0 32px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  font-family: var(--font-vcr);
  font-size: 14px;
}

.Footer_list__CF2A5 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
}

.Footer_listItemHeader__e4bBR {
  --text-shadow: 0px 0px 1px currentColor;
  text-shadow: var(--text-shadow);
}

.Footer_listItem__SyWXm a {
  line-height: 20px;
}
.Footer_listItem__SyWXm a::before {
  display: inline-block;
  content: '\25B8';
  margin-right: 8px;
  color: currentColor;
  text-decoration: unset;
}


.MenuComponent_nav__p3I8Q {
  margin: 8px 0;
}

.MenuComponent_menu__73G3o {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.MenuComponent_link__9zh4y {
  color: var(--highlight);
}

.HeaderComponent_title__yVFrQ {
  display: block;
  margin: 8px 0;
  font-size: 24px;
  position: relative;
}

.HeaderComponent_header__2USkI {
  font-family: var(--font-vcr);
}

:root {
  --primary: #FFF;
  --secondary: #33323a;
  --tertiary: #1b1c1e;
  --highlight: #facacf;
  --trueblack: #000000;
  --icon-color: invert(100%);
  --color-negative: #e8850d;
  --black-and-white-negative: var(--primary);
  --slider-default-width: calc(100vw - 16px);
  --slider-default-height: calc(100vh - 160px);
  --text-shadow: 0px 0px 1px currentColor;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  background-color: var(--trueblack);
  overscroll-behavior: none;
}

body {
  line-height: 1;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: var(--primary);
  font-size: 16px;
  margin: 8px;
  background: var(--trueblack);
  font-family: var(--font-negative);
}

body a {
  opacity: 1;
  color: var(--highlight);
}

body strong {
  font-weight: 700;
}

body em {
  font-style: italic;
}

body h1, body h2, body h3 {
  font-family: var(--font-negative);
  font-weight: 700;
  font-size: 16px;
}

head {
  &:has(link[rel='canonical'][href='https://killerrabb.it']) + body li#home a {
    text-shadow: var(--text-shadow);
  }
  &:has(link[rel='canonical'][href*='/posts']) + body li#posts a {
    text-shadow: var(--text-shadow);
  }
  &:has(link[rel='canonical'][href*='/videos']) + body li#videos a {
    text-shadow: var(--text-shadow);
  }
}


.footer {
  margin: 16px 0 32px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  font-family: var(--font-vcr);
  font-size: 14px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
}

.listItemHeader {
  --text-shadow: 0px 0px 1px currentColor;
  text-shadow: var(--text-shadow);
}

.listItem a {
  line-height: 20px;
}
.listItem a::before {
  display: inline-block;
  content: '\25B8';
  margin-right: 8px;
  color: currentColor;
  text-decoration: unset;
}

